import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import RouterView from '@/layouts/routerView.vue';
import DefaultView from '@/layouts/default.vue';
import Anonymous from '@/layouts/anonymous.vue';
import ImageRouter from '@/views/images/routers';
// import ProjectRouter from '@/views/project/routers';
import LoginRouter from '@/views/login/routers';
// import ScmRouter from '@/views/scm/routers';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: DefaultView,
      children: [{
        path: '',
        redirect: {
          name: 'imageList'
        }
      }, {
        path: 'image',
        component: RouterView,
        children: ImageRouter,
      }, {
        path: 'login',
        component: RouterView,
        children: LoginRouter
        // }, {
        //   path: 'scm',
        //   component: RouterView,
        //   children: ScmRouter
      }],
    },
    {
      path: '/image',
      component: Anonymous,
      children: [{
        path: 'anonymous',
        component: () => import('./views/images/containers/commonUploader.vue')
      }]
    },
    {
      path: '*',
      name: '404',
      component: () => import('./views/notfound/index.vue')
    }
  ],
});
