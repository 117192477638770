import Vue from 'vue';

import VueClipboard from 'vue-clipboard2'
import VueDragDrop from 'vue-drag-drop';
import VueJsModal from 'vue-js-modal';
import {
  Button,
  Menu,
  Form,
  Select,
  Input,
  Cascader,
  Radio,
  Upload,
  message,
  Card,
  InputNumber,
  DatePicker,
  Dropdown,
  Modal,
  Checkbox,
  Table,
  List,
  Tag,
  Steps,
  Row,
  Col,
  Tabs,
  Spin,
  Pagination,
  Breadcrumb,
  Popconfirm,
  Tooltip,
  Transfer,
  Tree,
  TreeSelect,
  Switch,
  Layout,
  Affix,
  Popover,
  Icon
} from 'ant-design-vue';

Vue.use(Button);
Vue.use(Menu);
Vue.use(Form);
Vue.use(Select);
Vue.use(Input);
Vue.use(Cascader);
Vue.use(Radio);
Vue.use(Icon);
Vue.use(Upload);
Vue.use(Card);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(Modal);
Vue.use(Checkbox);
Vue.use(Table);
Vue.use(List);
Vue.use(Tag);
Vue.use(Steps);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tabs);
Vue.use(Spin);
Vue.use(Pagination);
Vue.use(Breadcrumb);
Vue.use(Tabs);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(Transfer);
Vue.use(Tree);
Vue.use(TreeSelect);
Vue.use(Switch);
Vue.use(Layout);
Vue.use(Affix);
Vue.use(Popover);
Vue.use(VueDragDrop);
Vue.use(VueJsModal);

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$error = Modal.error;
Vue.prototype.$success = Modal.success;

Vue.use(VueClipboard)
