<template functional>
  <a-sub-menu :key="props.menuInfo.id">
    <span slot="title">
      <span>{{ props.menuInfo.name }}</span>
    </span>
    <template v-for="item in props.menuInfo.children">
      <a-menu-item v-if="!item.children || !item.children.length" :key="item.id">
        <router-link v-if="item.route" :to="$options.methods.getLinkByRoute(item.route, props.$route)">{{item.name}}</router-link>
        <span v-else>{{item.name}}</span>
      </a-menu-item>
      <sider-sub-menu v-else :key="item.id" :menu-info="item" :$route="props.$route"/>
    </template>
  </a-sub-menu>
</template>
<script>
export default {
  props: ['menuInfo', '$route'],
  name: 'sider-sub-menu',
  methods: {
    getLinkByRoute (route, $route) {
      let result = `${route}`;
      let { params } = $route;
      for (const key in params) {
        const value = params[key];
        result = result.replace(`:${key}`, value);
      }
      return result;
    }
  }
};
</script>
