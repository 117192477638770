import { StoreOptions } from 'vuex';
import request, { get, post } from '@/utils/request';

const DEFAULT_PAGE_INFO = {
  current: 1,
  pageSize: 10
}

export default {
  namespaced: true,
  state: {
    imageList: [],
    pageInfo: {
      ...DEFAULT_PAGE_INFO
    }
  },
  mutations: {
    setImageList (state, payload) {
      state.imageList = payload.imageList || [];
      state.pageInfo = payload.pageInfo
    }
  },
  actions: {
    async fetchImages ({ commit }, { pageInfo = DEFAULT_PAGE_INFO } = {}) {
      const { current: pageIndex, pageSize } = pageInfo;
      const { images, ...newPageInfo } = await get('/api/images', {
        params: {
          pageIndex,
          pageSize
        }
      });
      commit('setImageList', {
        imageList: images,
        pageInfo: {
          current: newPageInfo.pageIndex,
          pageSize: newPageInfo.pageSize,
          total: newPageInfo.totalCount
        }
      })
    },
    async uploadImage ({ commit, dispatch }, { file, anonymous = false }: { file: File, anonymous?: boolean }) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('filename', file.name);
      formData.append('filesize', `${file.size}`);
      const requestUrl = anonymous ? '/api/anonymous/image/upload' : '/api/image/upload';
      const result = await post(requestUrl, {
        data: formData
      });
      if (result && result.url) {
        if (anonymous) return result.url;
        const rst = await dispatch('saveImage', { url: result.url });
        return rst;
      } else {
        return false;
      }
    },
    async saveImage ({ commit }, { url }) {
      const result = await post('/api/images', { data: { url } });
      return result;
    }
  }
} as StoreOptions<any>
