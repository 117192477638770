const menuData = [
  // {
  //   id: '1',
  //   name: '首页',
  //   route: '/',
  //   matchedRoutes: ['/'],
  //   parentId: '',
  //   group: 'cms',
  //   backId: '',
  // },
  //
  {
    id: 'image',
    name: '图片',
    route: '/image/list',
    matchedRoutes: ['/image/list'],
    parentId: '',
    group: 'cms',
    backId: '',
  },
  // {
  //   id: '3',
  //   name: '页面管理',
  //   route: '/page/list',
  //   matchedRoutes: ['/page/list'],
  //   parentId: '',
  //   group: 'cms',
  //   backId: '',
  // }, {
  //   id: '3_1',
  //   name: '创建页面',
  //   route: '/page/create/:type',
  //   matchedRoutes: ['/page/create/:type'],
  //   parentId: '',
  //   group: 'cms_1',
  //   backId: '3',
  // }, {
  //   id: '3_2',
  //   name: '编辑页面',
  //   route: '/page/:pageId/edit',
  //   matchedRoutes: ['/page/:pageId/edit'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms_2',
  //   backId: '3',
  // }, {
  //   id: '3_4',
  //   name: '编辑数据',
  //   route: '/page/:pageId/editData',
  //   matchedRoutes: ['/page/:pageId/editData'],
  //   parentId: '',
  //   roles: ['developer', 'operator'],
  //   group: 'cms_2',
  //   backId: '3',
  // }, {
  //   id: '3_3',
  //   name: '发布页面',
  //   route: '/page/:pageId/release',
  //   matchedRoutes: ['/page/:pageId/release'],
  //   parentId: '',
  //   roles: ['developer', 'operator'],
  //   group: 'cms_2',
  //   backId: '3',
  // }, {
  //   id: '4',
  //   name: 'Schema管理',
  //   route: '/schema/list',
  //   matchedRoutes: ['/schema/list'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms',
  //   backId: '',
  // }, {
  //   id: '4_1',
  //   name: '创建Schema',
  //   route: '/schema/create',
  //   matchedRoutes: ['/schema/create'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms_1',
  //   backId: '4',
  // }, {
  //   id: '4_2',
  //   name: '编辑Schema',
  //   route: '/schema/:schemaId/edit',
  //   matchedRoutes: ['/schema/:schemaId/edit'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms',
  //   backId: '4',
  // }, {
  //   id: '5',
  //   name: '母版管理',
  //   route: '/template/list',
  //   matchedRoutes: ['/template/list'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms',
  //   backId: '',
  // }, {
  //   id: '5_1',
  //   name: '创建母版',
  //   route: '/template/create/:type',
  //   matchedRoutes: ['/template/create/:type'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms_1',
  //   backId: '5',
  // }, {
  //   id: '5_2',
  //   name: '母版页面',
  //   route: '/template/:templateId/edit/:type',
  //   matchedRoutes: ['/template/:templateId/edit/:type'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms_2',
  //   backId: '5',
  // }, {
  //   id: '5_3',
  //   name: '发布母版',
  //   route: '/template/:templateId/release',
  //   matchedRoutes: ['/template/:templateId/release'],
  //   parentId: '',
  //   roles: ['developer'],
  //   group: 'cms_2',
  //   backId: '5',
  // }
];

export default menuData;

export function getMenuDataById(id: string) {
  return menuData.filter((data) => data.id === id)[0];
}

export function getMenuDataByRoute(route: any) {
  return menuData.find((data) =>
    data.matchedRoutes.some((item: any) => {
      if (item instanceof RegExp) {
        return item.test(route);
      } else {
        return route === item;
      }
    }),
  );
}

export function getMenuGroup(currentMenu: any) {
  if (!currentMenu) { return []; }
  return menuData.filter(
    ({ group, backId }) =>
      currentMenu.group === group && currentMenu.backId === backId,
  );
}

export function getMenusByParentId(pid: string) {
  return menuData.filter((data) => data.parentId === pid);
}
