import Vue from 'vue';
import Vuex from 'vuex';
import imageStore from '@/views/images/modules'
import { get } from './utils/request';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    image: imageStore,
  },
  state: {
    userInfo: {}
  },
  mutations: {
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo;
    }
  },
  actions: {
    async fetchUserInfo ({ commit }) {
      const data = await get('/api/user/info');
      if (data) {
        commit('setUserInfo', data);
      }
    }
  },
});
