import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'ant-design-vue';
import router from '@/router';

declare global {
  interface Window {
    __csrf_token__: string;
  }
}

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const method = (config.method || '').toLocaleLowerCase();
  const token = localStorage.getItem('token')

  if (method === 'put' || method === 'post' || method === 'delete') {
    config.headers['x-csrf-token'] = token
  }

  config.headers['token'] = token
  return config;
})

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const url = config.url;
  if (url && url.match(/^\/scm\/v1/)) {
    config.url = `${process.env.VUE_APP_SCM_API_PREFIX || ''}${url}`;
  }
  return config;
});

const request = async (config: AxiosRequestConfig) => {
  try {
    const response = await axios(config);
    const { data: { code, data, result, message, msg } } = response;
    if (code !== 200 && code !== 0) {
      throw new Error(message || msg);
    } else {
      return data || result;
    }
  } catch (error: any) {
    message.error(error.message);
    throw error;
  }
};

export default request;

export async function get (url: string, config?: AxiosRequestConfig) {
  return await request({
    url,
    method: 'get',
    ...config
  });
}

export async function post (url: string, config?: AxiosRequestConfig) {
  return await request({
    url,
    method: 'post',
    ...config
  });
}

export async function put (url: string, config?: AxiosRequestConfig) {
  return await request({
    url,
    method: 'put',
    ...config
  });
}

export async function del (url: string, config?: AxiosRequestConfig) {
  return await request({
    url,
    method: 'delete',
    ...config
  });
}

axios.interceptors.response.use((response: AxiosResponse) => {
  const config = response.config;
  // token 过期, windows client内部token从客户端拉取，不做失效自动清除跳转登录的逻辑
  if (response.data.code === 401) {
    router.replace({
      name: 'login',
      query: {
        'auth_callback': window.location.href
      }
    })
  }
  return response;
});
