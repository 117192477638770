
















































// import Vue from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Layout } from 'ant-design-vue';
import Topbar from '@/components/topBar.vue';
import SideBar from '@/components/sideBar/index.vue';
import { getMenuDataById, getMenuDataByRoute, getMenuGroup, getMenusByParentId } from '@/constants/menuData';
import Title from '@/components/title';
import { State, Action } from 'vuex-class';
import RouterView from './routerView.vue';

const { Header, Sider, Content } = Layout;

@Component({
  components: {
    Header,
    Sider,
    Content,
    Topbar,
    Layout,
    SideBar,
    Title,
  },
})
export default class DefaultLayout extends Vue {
  @State
  userInfo: any;

  public menuData = this.getMenuData();

  public currentMenu: any = null;

  public getCurrentRoute () {
    const routes = this.$route.matched;
    return (routes || []).filter(({ regex }) => regex.test(this.$route.path)).map(({ path }) => path).join('');
  }

  mounted () {
    this.getMenuData();
  }

  filterPermittedMenuItem (menuList: Array<any>) {
    let userRoles: Array<string> = [];
    if (this.userInfo) {
      userRoles = this.userInfo.userRoles || [];
    }
    return menuList.filter(menuItem => {
      let { roles } = menuItem;
      if (!roles) return true;
      return roles.some((role: string) => {
        return userRoles.some(userRole => userRole === role)
      })
    })
  }

  @Watch('userInfo')
  public userInfoChanged () {
    this.getMenuData();
  }

  @Watch('$route.path')
  public getMenuData (): any {
    const currentRoute: any = this.getCurrentRoute();
    this.currentMenu = getMenuDataByRoute(currentRoute);
    const currentMenu = this.currentMenu || {};
    const currentMenuList: any[] = this.filterPermittedMenuItem(getMenuGroup(currentMenu));
    const organizedMenuData: any[] = [];
    currentMenuList.map((menu) => {
      const id = menu.id;
      const children = getMenusByParentId(id);
      menu.children = children;
      if (!menu.parentId) {
        organizedMenuData.push(menu);
      }
    });
    const backMenu = getMenuDataById(currentMenu.backId);
    let backUrl;
    if (backMenu) {
      backUrl = backMenu.route;
    }
    this.menuData = {
      currentId: currentMenu.id,
      currentMenu,
      menuData: organizedMenuData,
      backUrl,
    };
    return this.menuData;
  }
}

