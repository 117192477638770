













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Menu } from 'ant-design-vue';
import navData from '../constants/navData';
import * as request from '@/utils/request';
import { State, Action } from 'vuex-class';

const { Item: MenuItem } = Menu;

@Component({
  components: {
    Menu,
    MenuItem,
  },
})
export default class TopBar extends Vue {
  public navData: any[] = navData;

  @State
  public userInfo!: any;

  @Action
  public fetchUserInfo!: Function;

  @Prop({
    default: () => ({}),
  })
  public currentMenu: any;

  @Watch('$route.name')
  onRouteChange (newVal: string, oldVal: string) {
    if (oldVal === 'login') {
      this.fetchUserInfo();
    }
  }

  public async logout () {
    // await request.post('/passport/logout');
    localStorage.removeItem('token')
    this.$router.replace({
      name: 'login'
    });
  }

  public async mounted () {
    if (this.$route.name !== 'login') {
      this.fetchUserInfo();
    }
  }

  get selectedKeys () {
    const group = this.currentMenu.group;
    const currentNav = this.navData.find((item) => item.matchGroup.test(group));
    if (!currentNav) { return; }
    return [currentNav.id];
  }
}

