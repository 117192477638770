




























































import { Vue, Component } from 'vue-property-decorator';
import { getMenuDataByRoute, getMenuDataById } from '@/constants/menuData';

@Component
export default class BreadCrumbTitle extends Vue {
  get breadCrumbList (): any[] {
    function lookback (breadCrumbs: any[], menuNode: any): any[] {
      if (!menuNode) {
        return breadCrumbs;
      }
      const backNode = getMenuDataById(menuNode.backId);
      return lookback([menuNode, ...breadCrumbs], backNode);
    }

    const currentRoute = this.getCurrentRoute();
    const currentMenu = getMenuDataByRoute(currentRoute) || {};
    return lookback([], currentMenu);
  }

  public getRouteByMenuRoute (route: string) {
    const { params } = this.$route;
    return route.replace(/:\w+/g, (key: string) => {
      return params[key.replace(':', '')];
    });
  }

  public getCurrentRoute () {
    const routes = this.$route.matched;
    return (routes || []).filter(({ regex }) => regex.test(this.$route.path)).map(({ path }) => path).join('');
  }
}

