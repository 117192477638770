





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Menu } from 'ant-design-vue'
import SiderSubMenu from './subMenu.vue'

const { Item: MenuItem, ItemGroup: MenuItemGroup } = Menu

@Component({
  components: {
    Menu, MenuItem, MenuItemGroup, SiderSubMenu
  }
})
export default class SideBar extends Vue {
  @Prop()
  collapsed!: boolean;

  @Prop({
    type: Object,
    default: () => ({})
  })
  menuData: any;

  private openKeys: any = [];

  get selectedKeys () {
    let { currentId } = this.menuData
    if (!currentId) return []
    else return [currentId]
  }

  getRoute () {
    return this.$route;
  }

  getLinkByRoute (route: any) {
    let result = `${route}`
    let { params } = this.$route
    result = result.replace(/:\w+/g, key => {
      return params[key.replace(':', '')];
    });
    return result
  }
}
